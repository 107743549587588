import { DirectusUser, readMe } from '@directus/sdk'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { version } from '../package.json'
import './App.scss'
import Chart from './Chart'
import Invite from './Invite'
import UserPortal from './UserPortal'
import useDirectus, { Schema } from './hooks/useDirectus'
import { authenticatedUser } from './state/user-state'

const App = () => {
  const directus = useDirectus()
  const [user, setUser] = useRecoilState(authenticatedUser)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (directus === null) return
    const fetchData = async () => {
      setIsFetching(true)
      const loggedUser = await directus
        .request(readMe({ fields: ['*'] }))
        .catch(() => {
          setIsFetching(false)
        })
        .then((res) => res && { ...res, last_login: res.last_access ? parseISO(res.last_access) : null })

      console.log({ loggedUser })
      setIsFetching(false)
      loggedUser && setUser(loggedUser as DirectusUser<Schema>)
      console.log({ user })
      return loggedUser
    }

    fetchData().catch(console.error)
  }, [directus])
  console.log({ user })
  if (!directus || isFetching) return <div>Loading</div>
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={user ? <Chart /> : <UserPortal />} />
          <Route path="/invite" element={<Invite />} />
        </Routes>
      </Router>
      <div className="absolute bottom-0 right-0 flex justify-end">
        <p className="text-[#0f0f0f]">{version}</p>
      </div>
    </>
  )
}

export default App
